<template>
  <div class="h100 vh100 d-flex d-col">
    <TopToolbar />
    <div class="grow center wrap">
      <form @submit.prevent="post" class="container center d-col">
        <s-logo />
        <h1 class="d-flex green--text font-48 text-center">
          <div class="bold">Admin Portal</div>
        </h1>
        <div class="missingText textRed">
          <div v-if="message">
            {{ message }}
          </div>
        </div>
        <div v-if="message == 'Please use a customers account email address.'">
          <router-link
            class="green--text mt-15 font-14 d-flex center"
            to="/partners/login"
          >
            <div>Link to partner portal</div>
          </router-link>
        </div>
        <s-text-field
          v-model="username"
          details
          block
          :error="userError"
          :label="$t('login.email')"
        ></s-text-field>
        <s-text-field
          v-model="password"
          type="password"
          block
          password
          details
          :error="passwordError"
          :label="$t('login.password')"
        ></s-text-field>
        <s-btn block class="green mt-15" height="40">
          {{ $t("login.submit") }}
        </s-btn>
      </form>
    </div>
  </div>
</template>
  
  <script>
import TopToolbar from "@/components/Toolbars/TopToolbar.vue";
export default {
  components: {
    TopToolbar,
  },
  data() {
    return {
      username: "",
      password: "",
      message: "",
      userError: "",
      passwordError: "",
    };
  },
  methods: {
    async post() {
      let response = await this.$store.dispatch("auth/adminlogin", {
        email: this.username,
        password: this.password,
      });

      this.userError = "";
      this.passwordError = "";
      this.message = "";

      if (this.username == "") this.userError = this.$t("login.enter_email");
      if (this.password == "")
        this.passwordError = this.$t("login.enter_password");
      if (response.errorCode == "incorrect_email_password")
        this.message = this.$t("server." + response.errorCode);

      if (response.status == "ok") {
        this.$store.dispatch("admin/getAdminMe")
        this.$store.commit('user/setUser', response.user)
        this.$router.push("/admin/dashboard");
      }
    },
  },
};
</script>
  
  <style scoped lang="less">
.container {
  width: 100%;
  max-width: 400px;
  margin: 10px;
}

.textRed {
  color: #df5151;
}
</style>